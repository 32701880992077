// @ts-nocheck
import React from "react"
import emailjs from "emailjs-com"
import { Trans } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import { mixPanel } from "../../mixpanel/mixpanel"
import demoGroupsvg from "../../images/demoGroup.svg"
import demoContactsvg from "../../images/demoContact.svg"
import demoSearchsvg from "../../images/demoSearch.svg"
import demoHandssvg from "../../images/demoHands.svg"
import demoChatsvg from "../../images/demoChat.svg"
import demoEmailsvg from "../../images/demoEmail.svg"
import demoCallsvg from "../../images/demoCall.svg"
import phFlagIcon from "../../images/ph-flag-icon.svg"
import idFlagIcon from "../../images/id-flag-icon.svg"

import {
  Box,
  Grid,
  Flex,
  Input,
  Alert,
  AlertIcon,
  Button,
  GridItem,
  Select,
  CheckboxGroup,
  Checkbox,
  Image,
} from "@chakra-ui/react"
import HomeNavBar from "../Home/HomeNavBar"
import Footer from "../Home/Footer"

const GetADemo = () => {
  const [demoForm, setDemoForm] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    school_name: "",
    from_location: "",
    designation: "",
    demo_type: "",
    message: "",
    interest: [],
    typeOfInquiry: "",
  })

  const [hasError, setHasError] = React.useState(false)
  const [emailSent, setEmailSent] = React.useState(false)
  const [emailFailed, setEmailFailed] = React.useState(false)
  const [isLoading, setIsloading] = React.useState(false)

  const interestList = [
    "Premium School Management System",
    "Enrollment Solutions",
    "Payment Solutions",
    "Accounting Solutions",
    "Advance RFID Solutions",
    "School Branded Mobile App",
    "WELA Learning Management System",
    "Library Feature",
  ]

  const handleTrackClick = (value, formRef) => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", value)
      }
    }

    mixPanel.identify(formRef.email)
    mixPanel.people.set_once({
      $first_name: formRef.firstName || "",
      $last_name: formRef.lastName || "",
      $email: formRef.email,
      $phone: formRef.phone || "",
      $designation: formRef.designation || "",
      $schoolName: formRef.school_name || "",
      $fromLocation: formRef.from_location || "",
      $demoType: formRef.demo_type || "",
      $typeOfInquiry: formRef.typeOfInquiry || "",
      $interest: formRef.interest.join(", ") || "",
    })

    mixPanel.track(value)
  }

  const demoFormOnChange = (name, value) => {
    setHasError(false)
    setEmailSent(false)
    setDemoForm({
      ...demoForm,
      [name]: value,
    })
  }

  const submitForm = e => {
    e.preventDefault()
    setIsloading(true)
    const user_id = "user_3IOMKMqSXLd7nM8JpY2VC"
    const service_id = "service_1bwjs6h"
    const template_id = "template_X9Jbw2zy"

    const formRef = { ...demoForm }

    if (
      !demoForm.firstName ||
      !demoForm.lastName ||
      !demoForm.email ||
      !demoForm.phone ||
      !demoForm.designation ||
      !demoForm.from_location ||
      !demoForm.school_name ||
      !demoForm.demo_type ||
      !demoForm.typeOfInquiry ||
      demoForm.interest.length === 0
    ) {
      setHasError(true)
      setIsloading(false)
      return
    } else {
      emailjs.sendForm(service_id, template_id, e.target, user_id).then(
        result => {
          setDemoForm({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            school_name: "",
            from_location: "",
            designation: "",
            demoType: "",
            message: "",
            interest: [],
            typeOfInquiry: "",
          })
          setIsloading(false)
          setEmailSent(true)
          handleTrackClick("Booked Demo Sent", formRef)
        },
        error => {
          setEmailFailed(true)
          setIsloading(false)
          console.log("error", error)
        }
      )
    }
  }

  return (
    <>
      <HomeNavBar />
      <Box
        px={{ "2xl": "16rem", xl: "10rem", lg: "5rem", base: "1rem" }}
        pt={{ lg: "20", base: "20" }}
        position={"relative"}
      >
        <Box
          position={"absolute"}
          bgColor={"#7831EA"}
          top={0}
          left={0}
          zIndex={1}
          w={"100%"}
          h={{ lg: hasError || emailFailed ? "72%" : "75%", base: "35%" }}
        />
        <Grid
          templateColumns={{ lg: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
          gap={{ "2xl": 32, xl: 27, lg: 10, sm: 15, base: 5 }}
          position={"relative"}
          zIndex={10}
        >
          <Box
            pr={{ "2xl": "5", xl: "8", base: "0" }}
            mt={{ lg: "0", base: "0" }}
          >
            <Box
              color="#fff"
              fontWeight={{ lg: 700, base: 600 }}
              fontSize={{ xl: 35, lg: 27, base: 22 }}
              textAlign={{ lg: "left", base: "center" }}
            >
              <Trans>Grow digitally with us.</Trans>
            </Box>
            <Box
              fontWeight={400}
              fontSize={{ lg: 18, base: 16 }}
              py="4"
              color="#fff"
              textAlign={{ lg: "left", base: "center" }}
            >
              <Trans>
                We assure you, you will love our platform. Get started with your
                trial access.
              </Trans>
            </Box>
            <Box>
              <Image
                src={demoGroupsvg}
                alt="img"
                w={{ lg: "80%", base: "40%" }}
                mx={{ lg: "0", base: "auto" }}
              />
            </Box>
            <Box
              fontWeight={600}
              fontSize={20}
              pt="10"
              color="#fff"
              textAlign={{ lg: "left", base: "center" }}
            >
              <Trans>What can you expect in this demo</Trans>
            </Box>
            <Box mx={{ lg: 0, xs: 10, base: 0 }}>
              <Flex gap={2} mt={{ lg: "0", base: "4" }}>
                <Image src={demoSearchsvg} alt="img" w={{ lg: 12, base: 8 }} />
                <Box
                  fontWeight={400}
                  fontSize={{ lg: 14, base: 16 }}
                  py={{ lg: "4", base: "2" }}
                  color="#fff"
                >
                  <Trans>
                    In-Depth Product Overview: demonstrating how they can be
                    used to meet your specific needs.
                  </Trans>
                </Box>
              </Flex>
              <Flex gap={2}>
                <Image src={demoHandssvg} alt="img" w={{ lg: 12, base: 8 }} />
                <Box
                  fontWeight={400}
                  fontSize={{ lg: 14, base: 16 }}
                  py={{ lg: "4", base: "2" }}
                  color="#fff"
                >
                  <Trans>
                    Interactive Experience: see firsthand how our product works
                    and how it can benefit your business.
                  </Trans>
                </Box>
              </Flex>
              <Flex gap={2} id="stopper">
                <Image src={demoContactsvg} alt="img" w={{ lg: 12, base: 8 }} />
                <Box
                  fontWeight={400}
                  fontSize={{ lg: 14, base: 16 }}
                  py={{ lg: "4", base: "2" }}
                  color="#fff"
                >
                  <Trans>
                    Dedicated Time and Attention: Our 1 on 1 demo is focused
                    entirely on you and your needs.
                  </Trans>
                </Box>
              </Flex>
            </Box>
            <Box mt="16" py="2">
              <Box fontWeight={500} fontSize={24}>
                More ways to contact sales
              </Box>
              <Flex alignItems={"center"} gap={3} mt={5}>
                <Image src={demoChatsvg} alt="img" w={6} />
                <Box>
                  <Box fontSize={14} fontWeight={500}>
                    Chat with our sales team
                  </Box>
                  <a
                    style={{
                      color: "#8326EA",
                      fontSize: 14,
                      cursor: "pointer",
                    }}
                    href="https://www.facebook.com/welaschoolsystems"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.facebook.com/welaschoolsystems
                  </a>
                </Box>
              </Flex>
              <Flex alignItems={"center"} gap={3} mt={5}>
                <Image src={demoCallsvg} alt="img" w={6} />
                <Box>
                  <Box fontSize={14} fontWeight={500}>
                    Call our sales at
                  </Box>
                  <Box fontSize={14} color={"#8326EA"}>
                    <Flex alignItems={"center"} gap={2}>
                      <Image src={phFlagIcon} alt="ph" w="5" mb="0" my="auto" />
                      (+63) 917 1304 401 / (+63) 917 1701 977
                    </Flex>
                  </Box>
                  <Box fontSize={14} color={"#8326EA"}>
                    <Flex alignItems={"center"} gap={2}>
                      <Image
                        src={idFlagIcon}
                        border="1px solid #ccc"
                        rounded="sm"
                        alt="ph"
                        w="5"
                        mb="0"
                        my="auto"
                      />
                      (+62) 812 8331 0815
                    </Flex>
                  </Box>
                </Box>
              </Flex>
              <Flex alignItems={"center"} gap={3} mt={5}>
                <Image src={demoEmailsvg} alt="img" w={6} />
                <Box>
                  <Box fontSize={14} fontWeight={500}>
                    Email our sales team at
                  </Box>
                  <Box fontSize={14} color={"#8326EA"}>
                    hello@wela.online
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Box>
          <Flex justifyContent={"center"}>
            <Box
              bgColor="#fff"
              w="100%"
              rounded={"lg"}
              overflow={"hidden"}
              shadow={"md"}
            >
              <Box
                bgColor={"#EDF0FF"}
                color={"#8326EA"}
                textAlign={"center"}
                fontSize={{ xl: 24, base: 18 }}
                fontWeight={600}
                py={3}
              >
                <Trans>Talk to Sales or Request a Demo</Trans>
              </Box>
              <Box
                mx={{ lg: "2rem", base: "0.5" }}
                my="7"
                px={{ lg: "0", md: "8", base: "3" }}
              >
                <form onSubmit={submitForm}>
                  <Grid
                    templateColumns={{
                      base: "repeat(1, 1fr)",
                    }}
                    gap={"3"}
                  >
                    <GridItem>
                      <Select
                        id="typeOfInquiry"
                        name="typeOfInquiry"
                        placeholder="Type of Inquiry*"
                        fontSize={15}
                        mb="1"
                        w={"100%"}
                        onChange={e =>
                          demoFormOnChange("typeOfInquiry", e.target.value)
                        }
                      >
                        <option
                          key="newCustomer"
                          value="Interested - New Customer"
                        >
                          Interested - New Customer
                        </option>
                        <option
                          key="student"
                          value="Support -Student Assistance"
                        >
                          Support -Student Assistance
                        </option>
                        <option
                          key="parent"
                          value="Support - Parent Assistance"
                        >
                          Support - Parent Assistance
                        </option>
                        <option key="school" value="School Staff Assistance">
                          School Staff Assistance
                        </option>
                      </Select>
                    </GridItem>
                    <GridItem>
                      <Input
                        id="firstName"
                        name="firstName"
                        fontSize={15}
                        w="100%"
                        onChange={e =>
                          demoFormOnChange("firstName", e.target.value)
                        }
                        value={demoForm.firstName}
                        mb="1"
                        bgColor={"#EDF0FF"}
                        placeholder="First Name*"
                      />
                    </GridItem>
                    <GridItem>
                      <Input
                        id="lastName"
                        name="lastName"
                        fontSize={15}
                        w="100%"
                        onChange={e =>
                          demoFormOnChange("lastName", e.target.value)
                        }
                        value={demoForm.lastName}
                        mb="1"
                        bgColor={"#EDF0FF"}
                        placeholder="Last Name*"
                      />
                    </GridItem>

                    <Input
                      id="from_name"
                      name="from_name"
                      value={`${demoForm.firstName} ${demoForm.lastName}`}
                      display={"none"}
                    />

                    <GridItem>
                      <Input
                        id="reply_to"
                        name="reply_to"
                        fontSize={15}
                        w={"100%"}
                        onChange={e =>
                          demoFormOnChange("email", e.target.value)
                        }
                        value={demoForm.email}
                        mb="1"
                        bgColor={"#EDF0FF"}
                        placeholder="Email*"
                      />
                    </GridItem>
                    <GridItem>
                      <Input
                        type={"number"}
                        id="mobile"
                        name="mobile"
                        fontSize={15}
                        w={"100%"}
                        onChange={e =>
                          demoFormOnChange("phone", e.target.value)
                        }
                        value={demoForm.phone}
                        mb="1"
                        bgColor={"#EDF0FF"}
                        placeholder="Phone number*"
                      />
                    </GridItem>
                    <GridItem>
                      <Input
                        id="school_name"
                        name="school_name"
                        fontSize={15}
                        w={"100%"}
                        onChange={e =>
                          demoFormOnChange("school_name", e.target.value)
                        }
                        value={demoForm.school_name}
                        mb="1"
                        bgColor={"#EDF0FF"}
                        placeholder="School Name or Company Name*"
                      />
                    </GridItem>
                    <GridItem>
                      <Input
                        id="from_location"
                        name="from_location"
                        fontSize={15}
                        w={"100%"}
                        onChange={e =>
                          demoFormOnChange("from_location", e.target.value)
                        }
                        value={demoForm.from_location}
                        mb="1"
                        bgColor={"#EDF0FF"}
                        placeholder="Location of your school or company*"
                      />
                    </GridItem>
                    <GridItem>
                      <Select
                        id="designation"
                        name="designation"
                        placeholder="What is your designation*"
                        fontSize={15}
                        mb="1"
                        w={"100%"}
                        onChange={e =>
                          demoFormOnChange("designation", e.target.value)
                        }
                      >
                        <option key="Student" value="Student">
                          Student
                        </option>
                        <option key="Faculty" value="Faculty">
                          Faculty
                        </option>
                        <option key="SchoolAdmin" value="School Administator">
                          School Administator
                        </option>
                        <option key="SchoolPrincipal" value="School Principal">
                          School Principal
                        </option>
                        <option
                          key="InstitutionHead"
                          value="Institution Head/President"
                        >
                          Institution Head/President
                        </option>
                      </Select>
                    </GridItem>
                    <GridItem>
                      <Select
                        id="demo_type"
                        name="demo_type"
                        placeholder="Demo type*"
                        fontSize={15}
                        mb="1"
                        w={"100%"}
                        onChange={e =>
                          demoFormOnChange("demo_type", e.target.value)
                        }
                      >
                        <option
                          key="online"
                          value="Online (via google meet or zoom)"
                        >
                          Online (via google meet or zoom)
                        </option>
                        <option
                          key="onsite"
                          value="Onsite (let's conduct a demo in my school or company)"
                        >
                          Onsite (let's conduct a demo in my school or company)
                        </option>
                      </Select>
                    </GridItem>

                    <GridItem mb={10}>
                      <Box fontWeight={500}>
                        Select which area(s) you're interested in:
                      </Box>
                      <CheckboxGroup
                        id="interest"
                        name="interest"
                        colorScheme="purple"
                        onChange={e => demoFormOnChange("interest", e)}
                        value={demoForm.interest}
                      >
                        <Flex
                          flexWrap="wrap"
                          direction={"column"}
                          mt="3"
                          gap={2}
                        >
                          {interestList.map(interest => (
                            <Checkbox
                              key={interest}
                              value={interest}
                              _hover={{ color: "#8326EA" }}
                            >
                              {interest}
                            </Checkbox>
                          ))}
                        </Flex>
                      </CheckboxGroup>
                    </GridItem>
                  </Grid>

                  <Input
                    id="message_html"
                    name="message_html"
                    value={`Interested in ${demoForm.interest.join(", ")}`}
                    display={"none"}
                  />
                  <Flex justifyContent={"center"}>
                    <Button
                      rounded={"full"}
                      textAlign={"center"}
                      fontSize={15}
                      mb={hasError || emailSent || emailFailed ? "6" : "0"}
                      w={{ lg: "70%", base: "100%" }}
                      bgColor="#8326EA"
                      color="white"
                      fontWeight={400}
                      py="2"
                      type="submit"
                      _hover={{ bgColor: "#4e66db" }}
                      isLoading={isLoading}
                    >
                      <Trans>BOOK A DEMO</Trans>
                    </Button>
                  </Flex>
                  {hasError && (
                    <Flex justifyContent={"center"}>
                      <Alert status="error" w={"100%"} fontSize={15} h="9">
                        <AlertIcon />
                        Please fill important details.
                      </Alert>
                    </Flex>
                  )}

                  {emailFailed && (
                    <Flex justifyContent={"center"}>
                      <Alert status="error" w={"100%"} fontSize={15} h="9">
                        <AlertIcon />
                        Failed to send your email. Please try again later.
                      </Alert>
                    </Flex>
                  )}

                  {emailSent && (
                    <Flex justifyContent={"center"}>
                      <Alert status="success" w={"100%"} fontSize={15} h="9">
                        <AlertIcon />
                        Email sent. Thank you!
                      </Alert>
                    </Flex>
                  )}
                </form>
              </Box>
            </Box>
          </Flex>
        </Grid>
      </Box>
      <br />
      <br />
      <br />
      <Footer />
    </>
  )
}

export default GetADemo

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
